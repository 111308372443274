// src/components/CallList.js

import React, { useEffect, useState, useContext, useMemo, useCallback, Suspense } from 'react';
import {
  Box,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { WebSocketContext } from '../WebSocketContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);

// Laiska lataus alikomponenteille
const Filters = React.lazy(() => import('./CallList/Filters'));
const CallListTable = React.lazy(() => import('./CallList/CallListTable'));
const ErrorSnackbar = React.lazy(() => import('./CallList/ErrorSnackbar'));

const CallList = () => {
  const { t, i18n } = useTranslation();
  const { subscribe } = useContext(WebSocketContext);
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for date range filtering
  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  // State for location filtering
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  // State for timezone mapping
  const [locationTimezones, setLocationTimezones] = useState({});

  const authToken = localStorage.getItem('token');

  // Snackbar state
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // State for tracking downloading calls
  const [downloadingCalls, setDownloadingCalls] = useState([]);

  // Fetch locations from API
  const fetchLocations = useCallback(async () => {
    try {
      const response = await axios.get('/api/locations', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setLocations(response.data);

      // Luo mappi location_id -> timezone
      const timezoneMap = {};
      response.data.forEach((location) => {
        timezoneMap[location.id] = location.timezone || 'UTC';
      });
      setLocationTimezones(timezoneMap);

      // Jos vain yksi sijainti, valitaan se automaattisesti
      if (response.data.length === 1) {
        setSelectedLocation(response.data[0].id);
      }
    } catch (error) {
      console.error('Virhe toimipisteiden haussa:', error);
      setLoading(false);
      setSnackbar({ open: true, message: t('error_fetching_locations'), severity: 'error' });
    }
  }, [authToken, t]);

  // Fetch calls from API
  const fetchCalls = useCallback(async () => {
    try {
      const response = await axios.get('/api/calls', {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setCalls(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Virhe haettaessa puheluita:', error);
      setLoading(false);
      setSnackbar({ open: true, message: t('error_fetching_calls'), severity: 'error' });
    }
  }, [authToken, t]);

  useEffect(() => {
    fetchCalls();
    fetchLocations();
  }, [fetchCalls, fetchLocations]);

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (subscribe) {
      const unsubscribe = subscribe('update_calls', (data) => {
        setCalls((prevCalls) => [data, ...prevCalls]);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [subscribe]);

  // Function to format ISO 8601 duration to a human-readable string
  const formatDurationToString = useCallback((duration) => {
    if (!duration || typeof duration !== 'string') return 'N/A';
    if (!duration.startsWith('PT')) return 'N/A';

    const durationRegex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/;
    const matches = duration.match(durationRegex);

    if (!matches) return 'N/A';

    const hours = parseInt(matches[1] || 0, 10);
    const minutes = parseInt(matches[2] || 0, 10);
    const seconds = Math.floor(parseFloat(matches[3] || 0));

    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  }, []);

  // Filter calls by date range and location, and sort them descending
  const filteredCalls = useMemo(() => {
    return calls
      .filter((call) => {
        const callTimezone = locationTimezones[call.location_id] || 'UTC';
        const callDate = dayjs.utc(call.start_time).tz(callTimezone);
        if (startDate && callDate.isBefore(dayjs(startDate).startOf('day'))) return false;
        if (endDate && callDate.isAfter(dayjs(endDate).endOf('day'))) return false;
        if (selectedLocation) {
          return call.location_id === selectedLocation;
        }
        return true; // Näytä kaikki puhelut
      })
      .sort((a, b) => dayjs(b.start_time).unix() - dayjs(a.start_time).unix());
  }, [calls, startDate, endDate, selectedLocation, locationTimezones]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(filteredCalls.length / rowsPerPage);
  }, [filteredCalls.length, rowsPerPage]);

  // Get current page calls
  const currentCalls = useMemo(() => {
    const startIdx = (currentPage - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    return filteredCalls.slice(startIdx, endIdx);
  }, [filteredCalls, currentPage, rowsPerPage]);

  // Group current page calls by date (using correct timezone)
  const groupedCalls = useMemo(() => {
    const groups = {};
    currentCalls.forEach((call) => {
      const callTimezone = locationTimezones[call.location_id] || 'UTC';
      const date = dayjs.utc(call.start_time).tz(callTimezone).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(call);
    });
    return groups;
  }, [currentCalls, locationTimezones]);

  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  const handleLocationChange = useCallback((event) => {
    setSelectedLocation(event.target.value);
    setCurrentPage(1); // Reset page
  }, []);

  // Function to download Excel file
  const downloadExcel = useCallback(async () => {
    try {
      // Hae valitun sijainnin aikavyöhyke
      const selectedTimezone = locationTimezones[selectedLocation] || 'UTC';

      // Käytetään palvelimen kautta ladattavaa Excel-tiedostoa
      const response = await axios.get('/api/calls/export', {
        headers: { Authorization: `Bearer ${authToken}` },
        responseType: 'blob',
        params: {
          start_date: startDate ? startDate.tz(selectedTimezone).format() : undefined,
          end_date: endDate ? endDate.tz(selectedTimezone).format() : undefined,
          location_id: selectedLocation,
          timezone: selectedTimezone, // Lisätään aikavyöhyke
        },
      });

      // Selvitetään tiedostonimi vastausotsikoista
      const disposition = response.headers['content-disposition'];
      let filename = `call_list_${dayjs().format('YYYY-MM-DD')}.xlsx`;
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameMatch = disposition.match(/filename="?(.+)"?/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      // Luodaan blob ja käynnistetään lataus
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      saveAs(blob, filename);
    } catch (error) {
      console.error('Virhe Excel-tiedoston lataamisessa:', error);
      setSnackbar({ open: true, message: t('error_downloading_excel'), severity: 'error' });
    }
  }, [authToken, startDate, endDate, selectedLocation, locationTimezones, t]);

  // Funktio yksittäisen keskustelun lataamiseen
  const handleDownloadConversation = useCallback(
    async (call) => {
      try {
        // Lisää call.id latauslistaan
        setDownloadingCalls((prev) => [...prev, call.id]);

        // Näytetään latausindikaattori vain tälle napille
        setSnackbar({ open: true, message: t('downloading_conversation'), severity: 'info' });

        // Haetaan puhelun aikavyöhyke
        const callTimezone = locationTimezones[call.location_id] || 'UTC';

        // Käytetään oikeaa API-reittiä ja asetetaan responseType 'blob'
        const response = await axios.get(`/api/conversations/${call.id}/download`, {
          headers: { Authorization: `Bearer ${authToken}` },
          responseType: 'blob', // Tärkeää tiedoston lataamisessa
          params: {
            timezone: callTimezone, // Lisätään aikavyöhyke
          },
        });

        // Selvitetään tiedostonimi vastausotsikoista
        const disposition = response.headers['content-disposition'];
        let filename = `keskustelu_${call.id}.txt`; // Oletustiedostonimi
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameMatch = disposition.match(/filename="?(.+)"?/);
          if (filenameMatch && filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }

        // Luodaan blob ja käynnistetään lataus
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        saveAs(blob, filename);

        setSnackbar({ open: true, message: t('conversation_downloaded'), severity: 'success' });
      } catch (error) {
        console.error('Virhe keskustelun lataamisessa:', error);

        if (error.response && error.response.status === 404) {
          setSnackbar({ open: true, message: t('conversation_not_found'), severity: 'error' });
        } else {
          setSnackbar({ open: true, message: t('error_downloading_conversation'), severity: 'error' });
        }
      } finally {
        // Poista call.id latauslistalta
        setDownloadingCalls((prev) => prev.filter((id) => id !== call.id));
      }
    },
    [authToken, locationTimezones, t]
  );

  // Function to handle refresh
  const handleRefresh = useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCalls(), fetchLocations()]);
      setLoading(false);
    } catch (err) {
      console.error('Virhe päivittäessä dataa:', err);
      setLoading(false);
      setSnackbar({ open: true, message: t('error_refreshing_data'), severity: 'error' });
    }
  }, [fetchCalls, fetchLocations, t]);

  // Handle Snackbar Close
  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  // Päivitä dayjs locale ja LocalizationProvider adapterLocale
  useEffect(() => {
    const currentLanguage = i18n.language || 'fi';
    dayjs.locale(currentLanguage);
  }, [i18n.language]);

  // Määritä adapterLocale dynaamisesti
  const adapterLocale = i18n.language || 'fi';

  // ### JSX Rendering ###

  // Conditional Rendering for Loading State
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress aria-label={t('loading')} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: 'background.default',
        borderRadius: 2,
        boxShadow: 1,
        minHeight: '100vh',
      }}
    >
      {/* Lisää tilaa navigaatiomenuun */}
      <Box sx={(theme) => theme.mixins.toolbar} />

      {/* Filters */}
      <Suspense fallback={<CircularProgress />}>
        <Filters
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          locations={locations}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          downloadExcel={downloadExcel}
          handleRefresh={handleRefresh}
          loading={loading}
          t={t}
          adapterLocale={adapterLocale}
        />
      </Suspense>

      {/* Call List Table */}
      <Suspense fallback={<CircularProgress />}>
        <CallListTable
          groupedCalls={groupedCalls}
          formatDurationToString={formatDurationToString}
          locationTimezones={locationTimezones}
          t={t}
          handleDownloadConversation={handleDownloadConversation}
          downloadingCalls={downloadingCalls}
        />
      </Suspense>

      {/* Pagination */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          showFirstButton
          showLastButton
          aria-label={t('pagination')}
        />
      </Box>

      {/* Snackbar for Notifications */}
      <Suspense fallback={<CircularProgress />}>
        <ErrorSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          handleClose={handleSnackbarClose}
        />
      </Suspense>
    </Box>
  );
};

export default CallList;
