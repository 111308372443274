// src/contexts/ReservationContext.js

import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { WebSocketContext } from '../WebSocketContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const ReservationContext = createContext();

export const ReservationProvider = ({ children }) => {
  const { subscribe } = useContext(WebSocketContext);
  const [reservations, setReservations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  // Fetch locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('/api/locations');
        setLocations(response.data);
        if (response.data.length > 0) {
          setSelectedLocation(String(response.data[0].id)); // Ensure it's a string
          console.log(`Selected location set to: ${response.data[0].id}`);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
    fetchLocations();
  }, []);

  // Fetch reservations for a specific location
  const fetchReservations = useCallback(async (locationId) => {
    if (!locationId) {
      setReservations([]);
      return;
    }

    try {
      const response = await axios.get('/api/reservations/', {
        params: { location_id: locationId },
      });
      console.log('Fetched reservations:', response.data);
      // Store start and end times in UTC ISO 8601 format
      const events = response.data.map((reservation) => ({
        id: reservation.id,
        title: `${reservation.user_name}: ${reservation.description || 'Varaus'}`,
        start: reservation.start_time, // ISO string in UTC
        end: reservation.end_time,     // ISO string in UTC
        extendedProps: {
          user_phone: reservation.user_phone,
          description: reservation.description,
        },
      }));
      setReservations(events);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  }, []);

  // Fetch reservations when selectedLocation changes
  useEffect(() => {
    if (selectedLocation) {
      fetchReservations(selectedLocation);
    }
  }, [selectedLocation, fetchReservations]);

  // Handle WebSocket messages
  useEffect(() => {
    if (subscribe) {
      const handleNewReservation = (data) => {
        console.log('Received reservation_created:', data);
        if (String(data.location_id) === selectedLocation) { // Ensure same type
          // Add new reservation in UTC
          setReservations((prevReservations) => [
            ...prevReservations,
            {
              id: data.id,
              title: `${data.user_name}: ${data.description || 'Varaus'}`,
              start: data.start_time, // ISO string in UTC
              end: data.end_time,     // ISO string in UTC
              extendedProps: {
                user_phone: data.user_phone,
                description: data.description,
              },
            },
          ]);
        }
      };

      const handleUpdateReservation = (data) => {
        console.log('Received reservation_updated:', data);
        if (String(data.location_id) === selectedLocation) { // Ensure same type
          // Update reservation in UTC
          setReservations((prevReservations) =>
            prevReservations.map((reservation) =>
              reservation.id === data.id
                ? {
                    ...reservation,
                    title: `${data.user_name}: ${data.description || 'Varaus'}`,
                    start: data.start_time, // ISO string in UTC
                    end: data.end_time,     // ISO string in UTC
                    extendedProps: {
                      user_phone: data.user_phone,
                      description: data.description,
                    },
                  }
                : reservation
            )
          );
        }
      };

      const handleDeleteReservation = (data) => {
        console.log('Received reservation_deleted:', data);
        if (String(data.location_id) === selectedLocation) { // Ensure same type
          // Remove reservation
          setReservations((prevReservations) =>
            prevReservations.filter((reservation) => reservation.id !== data.id)
          );
        }
      };

      const unsubscribeNew = subscribe('reservation_created', handleNewReservation);
      const unsubscribeUpdate = subscribe('reservation_updated', handleUpdateReservation);
      const unsubscribeDelete = subscribe('reservation_deleted', handleDeleteReservation);

      return () => {
        unsubscribeNew();
        unsubscribeUpdate();
        unsubscribeDelete();
      };
    }
  }, [subscribe, selectedLocation]);

  return (
    <ReservationContext.Provider
      value={{
        reservations,
        setReservations,
        locations,
        selectedLocation,
        setSelectedLocation,
        fetchReservations,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};
