import React, { useState, useEffect, useContext, useCallback, useRef, Suspense } from 'react';
import { Box, CircularProgress, Typography, Divider, Grid } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { WebSocketContext } from '../WebSocketContext';
import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTheme } from '@mui/material/styles';

dayjs.extend(utc);
dayjs.extend(timezone);

// Laiska lataus alikomponenteille
const Filters = React.lazy(() => import('./Report/Filters'));
const FeedbackSummary = React.lazy(() => import('./Report/FeedbackSummary'));
const StatisticsTable = React.lazy(() => import('./Report/StatisticsTable'));
const FeedbackTable = React.lazy(() => import('./Report/FeedbackTable'));
const ErrorSnackbar = React.lazy(() => import('./Report/ErrorSnackbar'));

function Report() {
  const { t, i18n } = useTranslation();
  const { subscribe } = useContext(WebSocketContext);
  const theme = useTheme();

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [stats, setStats] = useState({
    total_calls: 0,
    total_call_requests: 0,
    handled_call_requests: 0,
    unhandled_call_requests: 0,
    average_call_duration: 0,
    positive_feedbacks: 0,
    negative_feedbacks: 0,
    translated_calls: 0,
  });
  const [feedbackStats, setFeedbackStats] = useState({
    bad: 0,
    moderate: 0,
    good: 0,
  });
  const [loadingStats, setLoadingStats] = useState(false);
  const [error, setError] = useState(null);

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  const isFetching = useRef(false);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const formatDate = useCallback((date) => {
    return date.utc().format();
  }, []);

  const fetchStats = useCallback(async () => {
    if ((selectedLocation && startDate && endDate) || selectedLocation === '') {
      setLoadingStats(true);
      setError(null);
      isFetching.current = true;
  
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
  
      const params = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
  
      if (selectedLocation) {
        params.location_id = selectedLocation;
      }
  
      try {
        if (selectedLocation) {
          const [statsResponse, feedbackResponse] = await Promise.all([
            axios.get('/api/reports/stats', { params }),
            axios.get('/api/reports/feedback_stats', { params }),
          ]);
          console.log('Stats Response:', statsResponse.data);
          console.log('Feedback Response:', feedbackResponse.data); // Lisää tämä rivi
  
          setStats(statsResponse.data);
          setFeedbackStats(feedbackResponse.data);
        } else {
          if (locations.length === 0) {
            setError(t('no_locations_available'));
            setSnackbar({ open: true, message: t('no_locations_available'), severity: 'error' });
            setLoadingStats(false);
            isFetching.current = false;
            return;
          }
  
          const statsPromises = locations.map((location) =>
            axios.get('/api/reports/stats', {
              params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate,
                location_id: location.id,
              },
            })
          );
  
          const feedbackStatsPromises = locations.map((location) =>
            axios.get('/api/reports/feedback_stats', {
              params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate,
                location_id: location.id,
              },
            })
          );
  
          const [statsResponses, feedbackStatsResponses] = await Promise.all([
            Promise.all(statsPromises),
            Promise.all(feedbackStatsPromises),
          ]);
  
          const aggregatedStats = statsResponses.reduce(
            (acc, response) => {
              const data = response.data;
              acc.total_calls += data.total_calls;
              acc.total_call_requests += data.total_call_requests;
              acc.handled_call_requests += data.handled_call_requests;
              acc.unhandled_call_requests += data.unhandled_call_requests;
              acc.average_call_duration += data.average_call_duration * data.total_calls;
              acc.positive_feedbacks += data.positive_feedbacks;
              acc.negative_feedbacks += data.negative_feedbacks;
              acc.translated_calls += data.translated_calls;
              return acc;
            },
            {
              total_calls: 0,
              total_call_requests: 0,
              handled_call_requests: 0,
              unhandled_call_requests: 0,
              average_call_duration: 0,
              positive_feedbacks: 0,
              negative_feedbacks: 0,
              translated_calls: 0,
            }
          );
  
          aggregatedStats.average_call_duration =
            aggregatedStats.total_calls > 0
              ? aggregatedStats.average_call_duration / aggregatedStats.total_calls
              : 0;
  
          const aggregatedFeedbackStats = feedbackStatsResponses.reduce(
            (acc, response) => {
              const data = response.data;
              acc.good += data.good;
              acc.moderate += data.moderate;
              acc.bad += data.bad;
              return acc;
            },
            { good: 0, moderate: 0, bad: 0 }
          );
  
          console.log('Aggregated Feedback Stats:', aggregatedFeedbackStats); // Lisää tämä
  
          setStats(aggregatedStats);
          setFeedbackStats(aggregatedFeedbackStats);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(t('error_fetching_stats'));
        setSnackbar({ open: true, message: t('error_fetching_stats'), severity: 'error' });
      } finally {
        setLoadingStats(false);
        isFetching.current = false;
      }
    }
  }, [selectedLocation, startDate, endDate, formatDate, t, locations]);
  

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('/api/locations');
        setLocations(response.data);
        setSelectedLocation('');
      } catch (error) {
        console.error('Error fetching locations:', error);
        setError(t('error_fetching_locations'));
        setSnackbar({ open: true, message: t('error_fetching_locations'), severity: 'error' });
      }
    };

    fetchLocations();
  }, [t]);

  useEffect(() => {
    if (locations.length > 0) {
      fetchStats();
    }
  }, [fetchStats, locations.length]);

  useEffect(() => {
    const handleNewCall = (data) => {
      if (!selectedLocation || (data.location && data.location.id === selectedLocation)) {
        fetchStats();
      }
    };

    const handleNewCallRequest = (data) => {
      if (!selectedLocation || data.location_id === selectedLocation) {
        fetchStats();
      }
    };

    const handleUpdateCallRequest = (data) => {
      if (!selectedLocation || data.location_id === selectedLocation) {
        fetchStats();
      }
    };

    const unsubscribeNewCall = subscribe('new_call', handleNewCall);
    const unsubscribeNewCallRequest = subscribe('new_call_request', handleNewCallRequest);
    const unsubscribeUpdateCallRequest = subscribe('update_call_request', handleUpdateCallRequest);

    return () => {
      unsubscribeNewCall();
      unsubscribeNewCallRequest();
      unsubscribeUpdateCallRequest();
    };
  }, [selectedLocation, subscribe, fetchStats]);

  useEffect(() => {
    const currentLanguage = i18n.language || 'fi';
    dayjs.locale(currentLanguage);
  }, [i18n.language]);

  const currentLanguage = i18n.language || 'fi';
  const adapterLocale = currentLanguage === 'en' ? 'en' : 'fi';

  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  // JSX Rendering
  return (
    <Box sx={{ padding: 3, backgroundColor: 'background.default', borderRadius: 2, boxShadow: 1 }}>
      <Box sx={theme.mixins.toolbar} />

      {/* Filters */}
      <Suspense fallback={<CircularProgress />}>
        <Filters
          t={t}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          locations={locations}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          fetchStats={fetchStats}
          loadingStats={loadingStats}
          adapterLocale={adapterLocale}
        />
      </Suspense>

      {loadingStats ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            backgroundColor: 'background.default',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="h6" color="error" sx={{ textAlign: 'center', marginTop: 4 }}>
          {error}
        </Typography>
      ) : stats ? (
        <>
          {/* Feedback Summary */}
          <Suspense fallback={<CircularProgress />}>
            <FeedbackSummary stats={stats} t={t} />
          </Suspense>

          {/* Divider */}
          <Box sx={{ marginY: 4 }}>
            <Divider />
          </Box>

          {/* Statistics Tables */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Suspense fallback={<CircularProgress />}>
                <StatisticsTable stats={stats} t={t} />
              </Suspense>
            </Grid>
            <Grid item xs={12} md={6}>
              <Suspense fallback={<CircularProgress />}>
                <FeedbackTable feedbackStats={feedbackStats} t={t} />
              </Suspense>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center', marginTop: 4 }}>
          {t('no_data')}
        </Typography>
      )}

      {/* Snackbar for Notifications */}
      <Suspense fallback={<CircularProgress />}>
        <ErrorSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          handleClose={handleSnackbarClose}
        />
      </Suspense>
    </Box>
  );
}

export default Report;
