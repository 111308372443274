// src/components/CallRequests/downloadConversationCallRequest.js

import axios from 'axios';
import { saveAs } from 'file-saver';

export async function downloadConversationForCallRequest(request_id, file_type = 'txt', authToken) {
  try {
    const response = await axios.get(
      `/api/call_request_conversations/${request_id}/download`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
        responseType: 'blob',
        params: { file_type }
      }
    );

    let filename = `conversation_request_${request_id}.${file_type === 'docx' ? 'docx' : 'txt'}`;
    const disposition = response.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameMatch = disposition.match(/filename="?(.+)"?/);
      if (filenameMatch && filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    saveAs(blob, filename);
    return true;
  } catch (error) {
    console.error('Error downloading conversation file:', error);
    throw error;
  }
}
