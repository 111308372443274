// src/components/CallRequests.js

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
  Suspense,
} from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CallRequestContext } from '../contexts/CallRequestContext';
import { useTheme } from '@mui/material/styles';

import { downloadConversationForCallRequest } from './CallRequests/downloadConversationCallRequest';

// Laiska lataus alikomponenteille
const Filters = React.lazy(() => import('./CallRequests/Filters'));
const CallRequestsTable = React.lazy(() => import('./CallRequests/CallRequestsTable'));
const FeedbackDialog = React.lazy(() => import('./CallRequests/FeedbackDialog'));
const NoteDialog = React.lazy(() => import('./CallRequests/NoteDialog'));
const ConfirmationDialog = React.lazy(() => import('./CallRequests/ConfirmationDialog'));
const SMSConversationDialog = React.lazy(() => import('./CallRequests/SMSConversationDialog'));
const ConfirmReleaseDialog = React.lazy(() => import('./CallRequests/ConfirmReleaseDialog'));

dayjs.extend(utc);
dayjs.extend(timezone);

const CallRequests = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Contextista tulevat arvot
  const {
    callRequests,
    setCallRequests,
    unhandledCount,
    unreadSmsRequests,
    setUnreadSmsRequests,
    subscribe,
  } = useContext(CallRequestContext);

  // Tilamuuttujat
  const [filter, setFilter] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [availableLocations, setAvailableLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmReleaseDialogOpen, setConfirmReleaseDialogOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [orderBy, setOrderBy] = useState('request_time');
  const [order, setOrder] = useState('desc');
  const [otherUnhandledRequests, setOtherUnhandledRequests] = useState([]);
  const [markAllAsHandled, setMarkAllAsHandled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);
  const [conversations, setConversations] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [downloadingCallRequests, setDownloadingCallRequests] = useState([]); // Lisätty tila latauksille

  const authToken = localStorage.getItem('token');
  const currentUsername = localStorage.getItem('username');

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  useEffect(() => {
    const currentLanguage = i18n.language || 'fi';
    dayjs.locale(currentLanguage);
  }, [i18n.language]);

  const adapterLocale = i18n.language === 'en' ? 'en' : 'fi';

  const normalizePhoneNumber = useCallback((number) => {
    return number.replace(/\D/g, ''); // Poistaa kaikki ei-numeeriset merkit
  }, []);

  // Väripaletti käyttäjille
  const userColorsRef = useRef({});

  const colorPalette = useMemo(
    () => [
      theme.palette.primary.light,
      theme.palette.secondary.light,
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.info.light,
      theme.palette.error.light,
      theme.palette.grey[200],
      theme.palette.grey[300],
      theme.palette.grey[400],
    ],
    [theme]
  );

  const getColorForUser = useCallback(
    (username) => {
      const userColors = userColorsRef.current;
      if (!userColors[username]) {
        const color = colorPalette[Object.keys(userColors).length % colorPalette.length];
        userColors[username] = color;
      }
      return userColors[username];
    },
    [colorPalette]
  );

  const getRowBackgroundColor = useCallback(
    (request) => {
      if (request.reserved_by === currentUsername) {
        return theme.palette.action.selected; // Käyttäjän oma varaus
      } else if (request.reserved_by) {
        return getColorForUser(request.reserved_by); // Muiden varaukset
      } else {
        return theme.palette.background.paper; // Tavallinen rivi
      }
    },
    [currentUsername, getColorForUser, theme]
  );

  // Final filtering and sorting
  const finalFilteredCallRequests = useMemo(() => {
    let filtered = callRequests;

    // Apply status filter
    if (statusFilter === 'handled') {
      filtered = filtered.filter((r) => r.status === 'handled');
    } else if (statusFilter === 'unhandled') {
      filtered = filtered.filter((r) => r.status === 'pending' || r.status === 'unhandled');
    }

    // Apply text filter
    if (filter) {
      filtered = filtered.filter(
        (request) =>
          (request.caller_name && request.caller_name.toLowerCase().includes(filter)) ||
          (request.caller_number && request.caller_number.toLowerCase().includes(filter)) ||
          (request.called_number && request.called_number.toLowerCase().includes(filter))
      );
    }

    // Apply date filter
    filtered = filtered.filter((request) => {
      const callDate = dayjs(request.request_time);
      if (startDate && callDate.isBefore(dayjs(startDate).startOf('day'))) return false;
      if (endDate && callDate.isAfter(dayjs(endDate).endOf('day'))) return false;
      return true;
    });

    // Apply location filter
    if (selectedLocation) {
      filtered = filtered.filter((r) => r.location_id === selectedLocation);
    }

    // Sort
    const sorted = [...filtered].sort((a, b) => {
      let aValue, bValue;
      switch (orderBy) {
        case 'caller_number':
          aValue = a.caller_number.toLowerCase();
          bValue = b.caller_number.toLowerCase();
          break;
        case 'called_number':
          aValue = a.called_number.toLowerCase();
          bValue = b.called_number.toLowerCase();
          break;
        case 'request_time':
        default:
          aValue = dayjs(a.request_time).unix();
          bValue = dayjs(b.request_time).unix();
      }

      if (order === 'asc') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return sorted;
  }, [
    callRequests,
    filter,
    statusFilter,
    startDate,
    endDate,
    order,
    orderBy,
    selectedLocation,
  ]);

  // Get current page call requests
  const currentCallRequests = useMemo(() => {
    const startIdx = (currentPage - 1) * 20;
    const endIdx = startIdx + 20;
    return finalFilteredCallRequests.slice(startIdx, endIdx);
  }, [finalFilteredCallRequests, currentPage]);

  // Group call requests by date
  const groupedCallRequests = useMemo(() => {
    const groups = {};
    currentCallRequests.forEach((request) => {
      const timezone = request.location?.timezone || 'UTC';
      const date = dayjs.utc(request.request_time).tz(timezone).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(request);
    });
    return groups;
  }, [currentCallRequests]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(finalFilteredCallRequests.length / 20); // rowsPerPage = 20
  }, [finalFilteredCallRequests.length]);

  // Fetch locations for the user
  useEffect(() => {
    let isMounted = true; 
    const fetchLocations = async () => {
      try {
        const locationsResponse = await axios.get('/api/locations', {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        if (isMounted) {
          setAvailableLocations(locationsResponse.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
        if (isMounted) {
          setSnackbar({
            open: true,
            message: t('error_fetching_locations'),
            severity: 'error',
          });
          setLoading(false);
        }
      }
    };
    fetchLocations();

    return () => {
      isMounted = false;
    };
  }, [authToken, t]);

  // Fetch call requests
  const fetchCallRequests = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/call_requests', {
        params: {
          locations: selectedLocation ? [selectedLocation] : [],
          start_date: startDate ? startDate.toISOString() : undefined,
          end_date: endDate ? endDate.toISOString() : undefined,
        },
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCallRequests(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching call requests:', error);
      setSnackbar({
        open: true,
        message: t('error_fetching_call_requests'),
        severity: 'error',
      });
      setLoading(false);
    }
  }, [selectedLocation, startDate, endDate, authToken, t, setCallRequests]);

  // Fetch call requests when filters change
  useEffect(() => {
    fetchCallRequests();
  }, [fetchCallRequests]);

  // Handler for receiving new SMS messages via WebSocket
  useEffect(() => {
    const handleNewSmsMessage = (data) => {
      console.log('Received new SMS message via WebSocket:', data);
      const requestId = data.request_id;
      const direction = data.direction;

      if (!requestId) {
        console.warn('Received SMS without request_id');
        return;
      }

      if (direction === 'inbound') {
        setUnreadSmsRequests((prevSet) => new Set(prevSet).add(requestId));
      }

      const newMsg = {
        id: data.id,
        direction: direction,
        body: data.body,
        timestamp: data.timestamp,
        from_number: direction === 'inbound' ? data.from_number : currentUsername,
        media_urls: data.media_urls || [],
      };

      setConversations((prev) => {
        const existingMessages = prev[requestId] || [];
        const isDuplicate = existingMessages.some((msg) => msg.id === newMsg.id);
        if (isDuplicate) return prev;
        return {
          ...prev,
          [requestId]: [...existingMessages, newMsg],
        };
      });
    };

    const unsubscribe = subscribe('new_sms_message', handleNewSmsMessage);

    return () => {
      unsubscribe();
    };
  }, [subscribe, currentUsername, setUnreadSmsRequests]);

  // Handler for downloading conversation
  const handleDownloadConversation = useCallback(
    async (request) => {
      setDownloadingCallRequests((prev) => [...prev, request.id]);
      try {
        await downloadConversationForCallRequest(request.id, 'txt', authToken);
        setSnackbar({
          open: true,
          message: t('download_successful'),
          severity: 'success',
        });
      } catch (error) {
        console.error('Download error:', error);
        setSnackbar({
          open: true,
          message: t('download_failed'),
          severity: 'error',
        });
      } finally {
        setDownloadingCallRequests((prev) => prev.filter((id) => id !== request.id));
      }
    },
    [authToken, t]
  );

  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value.toLowerCase());
    setCurrentPage(1);
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilter('');
    setCurrentPage(1);
  }, []);

  const handleLocationChange = useCallback((event) => {
    setSelectedLocation(event.target.value);
    setCurrentPage(1);
  }, []);

  const handleStatusFilterChange = useCallback((event, newValue) => {
    if (newValue !== null) {
      setStatusFilter(newValue);
      setCurrentPage(1);
    }
  }, []);

  const handleRequestSort = useCallback((property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [orderBy, order]);

  const handleOpenNoteDialog = useCallback((request) => {
    setSelectedRequest(request);
    setNoteText(request.comments || '');
    setNoteDialogOpen(true);
  }, []);

  const handleOpenSmsDialog = useCallback(
    async (request) => {
      setSelectedRequest(request);
      setSmsDialogOpen(true);
      setLoadingMessages(true);
      try {
        const response = await axios.get('/sms/conversation', {
          params: {
            request_id: request.id,
          },
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setConversations((prev) => ({ ...prev, [request.id]: response.data }));
        setLoadingMessages(false);

        setUnreadSmsRequests((prevSet) => {
          const newSet = new Set(prevSet);
          newSet.delete(request.id);
          console.log('Removed requestId from unreadSmsRequests:', request.id, Array.from(newSet));
          return newSet;
        });
      } catch (error) {
        console.error('Error fetching SMS conversation:', error);
        setSnackbar({
          open: true,
          message: t('error_fetching_sms_conversation'),
          severity: 'error',
        });
        setLoadingMessages(false);
      }
    },
    [authToken, t, setUnreadSmsRequests]
  );

  const sendMessage = useCallback(
    async (mediaUrls = []) => {
      if (!selectedRequest) return;
      try {
        const messageData = {
          to_number: selectedRequest.caller_number,
          body: newMessage,
          location_id: selectedRequest.location_id,
          request_id: selectedRequest.id,
          media_urls: mediaUrls.length > 0 ? mediaUrls : null,
        };
        const response = await axios.post('/sms/send', messageData, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        setNewMessage('');
        setUnreadSmsRequests((prevSet) => {
          const newSet = new Set(prevSet);
          newSet.delete(selectedRequest.id);
          return newSet;
        });

        setSnackbar({ open: true, message: t('sms_sent_successfully'), severity: 'success' });
      } catch (error) {
        console.error('Error sending SMS:', error);
        setSnackbar({ open: true, message: t('error_sending_sms'), severity: 'error' });
      }
    },
    [selectedRequest, newMessage, authToken, t, setUnreadSmsRequests, setNewMessage, setSnackbar]
  );

  const handleMarkAsHandled = useCallback(
    (request) => {
      setSelectedRequest(request);
      setFeedback('');

      const callerNumber = request.caller_number; 

      const otherRequests = callRequests.filter(
        (r) =>
          r.caller_number === callerNumber &&
          (r.status === 'pending' || r.status === 'unhandled') &&
          r.id !== request.id
      );

      if (otherRequests.length > 0) {
        setOtherUnhandledRequests(otherRequests);
      } else {
        setOtherUnhandledRequests([]);
      }

      if (request.status === 'handled') {
        setConfirmDialogOpen(true);
      } else {
        setFeedbackDialogOpen(true);
      }
    },
    [callRequests]
  );

  const confirmUnmarkHandled = useCallback(async () => {
    try {
      const updatedRequest = {
        status: 'unhandled',
        handled_by: null,
        handled_time: null,
        feedback: '',
      };
      const response = await axios.put(`/api/call_requests/${selectedRequest.id}`, updatedRequest, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? response.data : r))
      );
      setConfirmDialogOpen(false);
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('unmarked_as_handled'), severity: 'success' });
    } catch (error) {
      console.error('Error updating call request:', error);
      setSnackbar({
        open: true,
        message: t('error_updating_call_request'),
        severity: 'error',
      });
      setConfirmDialogOpen(false);
    }
  }, [selectedRequest, authToken, setCallRequests, t]);

  const handleSaveFeedback = useCallback(async () => {
    try {
      const requestsToUpdate = [selectedRequest];

      if (markAllAsHandled && otherUnhandledRequests.length > 0) {
        requestsToUpdate.push(...otherUnhandledRequests);
      }

      const updates = requestsToUpdate.map(async (requestToUpdate) => {
        const updatedRequest = {
          status: 'handled',
          handled_by: currentUsername,
          handled_time: dayjs().toISOString(),
          feedback: feedback,
        };
        const response = await axios.put(
          `/api/call_requests/${requestToUpdate.id}`,
          updatedRequest,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );

        await axios.post(`/api/call_requests/${requestToUpdate.id}/release`, null, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        return { ...response.data, reserved_by: null };
      });

      const updatedRequests = await Promise.all(updates);

      setCallRequests((prevRequests) =>
        prevRequests.map((r) => updatedRequests.find((u) => u.id === r.id) || r)
      );

      setFeedbackDialogOpen(false);
      setSelectedRequest(null);
      setMarkAllAsHandled(false);
      setOtherUnhandledRequests([]);
      setSnackbar({ open: true, message: t('marked_as_handled'), severity: 'success' });
    } catch (error) {
      console.error('Error updating call requests:', error);
      setSnackbar({
        open: true,
        message: t('error_updating_call_requests'),
        severity: 'error',
      });
      setFeedbackDialogOpen(false);
    }
  }, [
    selectedRequest,
    markAllAsHandled,
    otherUnhandledRequests,
    currentUsername,
    authToken,
    setCallRequests,
    feedback,
    t,
  ]);

  const handleSaveNote = useCallback(async () => {
    try {
      const updatedRequest = {
        comments: noteText,
      };
      const response = await axios.put(
        `/api/call_requests/${selectedRequest.id}`,
        updatedRequest,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? response.data : r))
      );
      setNoteDialogOpen(false);
      setNoteText('');
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('note_saved'), severity: 'success' });
    } catch (error) {
      console.error('Error updating call request:', error);
      setSnackbar({
        open: true,
        message: t('error_updating_call_request'),
        severity: 'error',
      });
      setNoteDialogOpen(false);
    }
  }, [selectedRequest, noteText, authToken, setCallRequests, t]);

  const handleRemoveNote = useCallback(async () => {
    try {
      const updatedRequest = {
        comments: '',
      };
      const response = await axios.put(
        `/api/call_requests/${selectedRequest.id}`,
        updatedRequest,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? response.data : r))
      );
      setNoteDialogOpen(false);
      setNoteText('');
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('note_removed'), severity: 'success' });
    } catch (error) {
      console.error('Error removing note:', error);
      setSnackbar({
        open: true,
        message: t('error_removing_note'),
        severity: 'error',
      });
      setNoteDialogOpen(false);
    }
  }, [selectedRequest, authToken, setCallRequests, t]);

  const getStatusColorFn = useCallback((request) => {
    if (request.status === 'handled') {
      return 'success.main';
    }
    const requestTime = dayjs(request.request_time);
    const now = dayjs();
    const diffMinutes = now.diff(requestTime, 'minute');
    if (diffMinutes <= 30) {
      return 'warning.main';
    } else {
      return 'error.main';
    }
  }, []);

  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handleRowClick = useCallback(
    async (request) => {
      if (request.reserved_by && request.reserved_by !== currentUsername) {
        setSelectedRequest(request);
        setConfirmReleaseDialogOpen(true);
        return;
      }
      try {
        const response = await axios.post(`/api/call_requests/${request.id}/reserve`, null, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setCallRequests((prevRequests) =>
          prevRequests.map((r) => (r.id === request.id ? { ...r, ...response.data } : r))
        );
      } catch (error) {
        console.error('Error reserving/unreserving call request:', error);
        if (error.response && error.response.status === 409) {
          setSnackbar({
            open: true,
            message: t('call_request_reserved_by_other'),
            severity: 'warning',
          });
        } else {
          setSnackbar({
            open: true,
            message: t('error_updating_call_request'),
            severity: 'error',
          });
        }
      }
    },
    [authToken, currentUsername, setCallRequests, t]
  );

  const handleConfirmRelease = useCallback(async () => {
    try {
      const response = await axios.post(`/api/call_requests/${selectedRequest.id}/release`, null, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? { ...r, ...response.data } : r))
      );
      setConfirmReleaseDialogOpen(false);
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('reservation_released'), severity: 'success' });
    } catch (error) {
      console.error('Error releasing reservation:', error);
      setSnackbar({
        open: true,
        message: t('error_releasing_reservation'),
        severity: 'error',
      });
      setConfirmReleaseDialogOpen(false);
    }
  }, [selectedRequest, authToken, setCallRequests, t]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress aria-label={t('loading')} />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
      <Box
        sx={{
          padding: 3,
          backgroundColor: 'background.default',
          borderRadius: 2,
          boxShadow: 1,
          minHeight: '100vh',
        }}
      >
        <Box sx={{ ...((theme) => theme.mixins.toolbar) }} />

        <Suspense fallback={<CircularProgress />}>
          <Filters
            filter={filter}
            handleFilterChange={handleFilterChange}
            handleClearFilter={handleClearFilter}
            selectedLocation={selectedLocation}
            handleLocationChange={handleLocationChange}
            availableLocations={availableLocations}
            statusFilter={statusFilter}
            handleStatusFilterChange={handleStatusFilterChange}
            unhandledCount={unhandledCount}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            adapterLocale={adapterLocale}
          />
        </Suspense>

        <Suspense fallback={<CircularProgress />}>
          <CallRequestsTable
            groupedCallRequests={groupedCallRequests}
            handleMarkAsHandled={handleMarkAsHandled}
            getStatusColor={getStatusColorFn}
            orderBy={orderBy}
            order={order}
            handleRequestSort={handleRequestSort}
            handleOpenNoteDialog={handleOpenNoteDialog}
            handleOpenSmsDialog={handleOpenSmsDialog}
            handleDownloadConversation={handleDownloadConversation}
            t={t}
            unreadSmsRequests={unreadSmsRequests}
            normalizePhoneNumber={normalizePhoneNumber}
            handleRowClick={handleRowClick}
            getRowBackgroundColor={getRowBackgroundColor}
            downloadingCallRequests={downloadingCallRequests}
          />
        </Suspense>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            showFirstButton
            showLastButton
            aria-label={t('pagination')}
          />
        </Box>

        <Suspense fallback={<CircularProgress />}>
          <FeedbackDialog
            open={feedbackDialogOpen}
            handleClose={() => setFeedbackDialogOpen(false)}
            feedback={feedback}
            setFeedback={setFeedback}
            handleSaveFeedback={handleSaveFeedback}
            markAllAsHandled={markAllAsHandled}
            setMarkAllAsHandled={setMarkAllAsHandled}
            otherUnhandledRequests={otherUnhandledRequests}
            t={t}
          />
        </Suspense>

        <Suspense fallback={<CircularProgress />}>
          <NoteDialog
            open={noteDialogOpen}
            handleClose={() => setNoteDialogOpen(false)}
            selectedRequest={selectedRequest}
            noteText={noteText}
            setNoteText={setNoteText}
            handleSaveNote={handleSaveNote}
            handleRemoveNote={handleRemoveNote}
          />
        </Suspense>

        <Suspense fallback={<CircularProgress />}>
          <ConfirmationDialog
            open={confirmDialogOpen}
            handleClose={() => setConfirmDialogOpen(false)}
            handleConfirm={confirmUnmarkHandled}
          />
        </Suspense>

        <Suspense fallback={<CircularProgress />}>
          <SMSConversationDialog
            open={smsDialogOpen}
            handleClose={() => setSmsDialogOpen(false)}
            conversation={conversations[selectedRequest?.id] || []}
            sendMessage={sendMessage}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            t={t}
            loadingMessages={loadingMessages}
          />
        </Suspense>

        <Suspense fallback={<CircularProgress />}>
          <ConfirmReleaseDialog
            open={confirmReleaseDialogOpen}
            handleClose={() => setConfirmReleaseDialogOpen(false)}
            handleConfirm={handleConfirmRelease}
          />
        </Suspense>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default CallRequests;
