// src/components/Reservation.js

import React, { useState, useEffect, useContext, useCallback, useRef, Suspense } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { WebSocketContext } from '../WebSocketContext';
import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/plugin/customParseFormat';

// Laiska lataus alikomponenteille
const CalendarToolbar = React.lazy(() => import('./Reservations/CalendarToolbar'));
const ReservationCalendar = React.lazy(() => import('./Reservations/ReservationCalendar'));
const ReservationDialog = React.lazy(() => import('./Reservations/ReservationDialog'));
const SnackbarNotification = React.lazy(() => import('./Reservations/SnackbarNotification'));

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(require('dayjs/plugin/customParseFormat'));

function Reservation() {
  const { t, i18n } = useTranslation();
  const { subscribe } = useContext(WebSocketContext);
  const theme = useTheme();

  const [reservations, setReservations] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('create');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadingReservations, setLoadingReservations] = useState(false);
  const [timezoneName, setTimezoneName] = useState('Europe/Helsinki');

  const calendarRef = useRef(null);

  const initializeNewReservation = useCallback(() => {
    const now = dayjs().tz(timezoneName).minute(0).second(0).millisecond(0);
    const start_time = now.minute() > 0 ? now.add(1, 'hour').minute(0) : now;
    const end_time = start_time.add(1, 'hour');

    return {
      id: null,
      user_name: '',
      user_phone: '',
      start_time: start_time,
      end_time: end_time,
      description: '',
    };
  }, [timezoneName]);

  const [newReservation, setNewReservation] = useState(initializeNewReservation());

  const fetchLocations = useCallback(async () => {
    try {
      const response = await axios.get('/api/locations/');
      setLocations(response.data);
      if (response.data.length > 0 && !selectedLocation) {
        const firstLocation = response.data[0];
        setSelectedLocation(firstLocation.id);
        const tz = firstLocation.timezone || 'Europe/Helsinki';
        setTimezoneName(tz);
        dayjs.tz.setDefault(tz);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setSnackbar({
        open: true,
        message: t('error_fetching_locations'),
        severity: 'error',
      });
    }
  }, [t, selectedLocation]);

  useEffect(() => {
    if (selectedLocation && locations.length > 0) {
      const selectedLoc = locations.find((loc) => loc.id === selectedLocation);
      if (selectedLoc) {
        const tz = selectedLoc.timezone || 'Europe/Helsinki';
        setTimezoneName(tz);
        dayjs.tz.setDefault(tz);
        setNewReservation(initializeNewReservation());
      }
    }
  }, [selectedLocation, locations, initializeNewReservation]);

  const fetchReservations = useCallback(async () => {
    if (selectedLocation === null) {
      setReservations([]);
      return;
    }
    console.log('Fetching reservations for location:', selectedLocation);
    setLoadingReservations(true);
    try {
      const response = await axios.get('/api/reservations/', {
        params: { location_id: selectedLocation },
      });
      console.log('Reservations fetched:', response.data);
      const events = response.data.map((reservation) => ({
        id: reservation.id,
        title: `${reservation.user_name}: ${reservation.description || t('reservation')}`,
        start: dayjs.utc(reservation.start_time).tz(timezoneName).toDate(),
        end: dayjs.utc(reservation.end_time).tz(timezoneName).toDate(),
        extendedProps: {
          user_phone: reservation.user_phone,
          description: reservation.description,
        },
      }));
      setReservations(events);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      setSnackbar({
        open: true,
        message: t('error_fetching_reservations'),
        severity: 'error',
      });
    } finally {
      setLoadingReservations(false);
    }
  }, [selectedLocation, t, timezoneName]);

  const handleCreateReservation = async () => {
    if (newReservation.end_time.isBefore(newReservation.start_time)) {
      setSnackbar({
        open: true,
        message: t('end_time_before_start_time'),
        severity: 'error',
      });
      return;
    }

    try {
      const payload = {
        user_name: newReservation.user_name,
        user_phone: newReservation.user_phone,
        start_time: newReservation.start_time.toISOString(),
        end_time: newReservation.end_time.toISOString(),
        description: newReservation.description,
        location_id: selectedLocation,
      };

      await axios.post('/api/reservations/', payload);

      setOpenDialog(false);
      resetReservationForm();
      setSnackbar({
        open: true,
        message: t('reservation_created'),
        severity: 'success',
      });
      fetchReservations();
    } catch (error) {
      console.error('Error creating reservation:', error);
      let errorMessage = t('error_creating_reservation');
      if (error.response && error.response.data && error.response.data.detail) {
        const details = error.response.data.detail;
        if (Array.isArray(details) && details.length > 0) {
          errorMessage = details.map((d) => d.msg).join(', ');
        }
      }
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    }
  };

  const handleUpdateReservation = async () => {
    if (newReservation.end_time.isBefore(newReservation.start_time)) {
      setSnackbar({
        open: true,
        message: t('end_time_before_start_time'),
        severity: 'error',
      });
      return;
    }

    try {
      const payload = {
        user_name: newReservation.user_name,
        user_phone: newReservation.user_phone,
        start_time: newReservation.start_time.toISOString(),
        end_time: newReservation.end_time.toISOString(),
        description: newReservation.description,
        location_id: selectedLocation,
      };

      await axios.put(`/api/reservations/${newReservation.id}/`, payload);

      setOpenDialog(false);
      resetReservationForm();
      setSnackbar({
        open: true,
        message: t('reservation_updated'),
        severity: 'success',
      });
      fetchReservations();
    } catch (error) {
      console.error('Error updating reservation:', error);
      let errorMessage = t('error_updating_reservation');
      if (error.response && error.response.data && error.response.data.detail) {
        const details = error.response.data.detail;
        if (Array.isArray(details) && details.length > 0) {
          errorMessage = details.map((d) => d.msg).join(', ');
        }
      }
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    }
  };

  const handleDeleteReservation = async () => {
    try {
      await axios.delete(`/api/reservations/${newReservation.id}/`);

      setOpenDialog(false);
      resetReservationForm();
      setSnackbar({
        open: true,
        message: t('reservation_deleted'),
        severity: 'success',
      });
      fetchReservations();
    } catch (error) {
      console.error('Error deleting reservation:', error);
      let errorMessage = t('error_deleting_reservation');
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail;
      }
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    }
  };

  const resetReservationForm = () => {
    setNewReservation(initializeNewReservation());
    setDialogMode('create');
  };

  const handleEventClick = (info) => {
    setDialogMode('edit');
    setNewReservation({
      id: info.event.id,
      user_name: info.event.title.split(':')[0],
      user_phone: info.event.extendedProps.user_phone,
      start_time: dayjs(info.event.start).tz(timezoneName),
      end_time: dayjs(info.event.end).tz(timezoneName),
      description: info.event.extendedProps.description,
    });
    setOpenDialog(true);
  };

  const handleDateSelect = (selectInfo) => {
    const selectedStart = dayjs(selectInfo.start).tz(timezoneName);
    const selectedEnd = dayjs(selectInfo.end).tz(timezoneName);
    const now = dayjs().tz(timezoneName);

    if (selectedStart.isBefore(now)) {
      setSnackbar({
        open: true,
        message: t('cannot_select_past_time'),
        severity: 'error',
      });
      return;
    }

    setDialogMode('create');
    setNewReservation({
      id: null,
      user_name: '',
      user_phone: '',
      start_time: selectedStart,
      end_time: selectedEnd,
      description: '',
    });
    setOpenDialog(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    if (selectedLocation) {
      fetchReservations();
    }
  }, [selectedLocation, fetchReservations]);

  useEffect(() => {
    const handleReservationCreated = (data) => {
      if (data.location_id === selectedLocation) {
        fetchReservations();
      }
    };

    const handleReservationUpdated = (data) => {
      if (data.location_id === selectedLocation) {
        fetchReservations();
      }
    };

    const handleReservationDeleted = (data) => {
      if (data.location_id === selectedLocation) {
        fetchReservations();
      }
    };

    const unsubscribeCreated = subscribe('reservation_created', handleReservationCreated);
    const unsubscribeUpdated = subscribe('reservation_updated', handleReservationUpdated);
    const unsubscribeDeleted = subscribe('reservation_deleted', handleReservationDeleted);

    return () => {
      unsubscribeCreated();
      unsubscribeUpdated();
      unsubscribeDeleted();
    };
  }, [selectedLocation, subscribe, fetchReservations]);

  useEffect(() => {
    const locale = i18n.language.startsWith('en') ? 'en' : i18n.language;
    dayjs.locale(locale);
  }, [i18n.language]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language === 'en' ? 'en' : i18n.language}
    >
      <Box
        sx={{
          padding: 3,
          backgroundColor: 'background.default',
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 4 }}>
          {t('reservations')}
        </Typography>

        {/* Toimipisteen valinta ja Päivitä-painike */}
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="location-select-label">{t('select_location')}</InputLabel>
              <Select
                labelId="location-select-label"
                value={selectedLocation || ''}
                onChange={(e) => setSelectedLocation(e.target.value)}
                label={t('select_location')}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={fetchReservations}
              disabled={loadingReservations}
              sx={{ minWidth: 150 }}
              startIcon={<RefreshIcon />}
            >
              {loadingReservations ? <CircularProgress size={24} color="inherit" /> : t('refresh')}
            </Button>
          </Grid>
        </Grid>

        {/* Kalenterin navigointipalkki */}
        <Suspense fallback={<CircularProgress />}>
          <CalendarToolbar
            calendarRef={calendarRef}
            t={t}
          />
        </Suspense>

        {/* Kalenteri */}
        <Box sx={{ marginTop: 3 }}>
          <Suspense fallback={<CircularProgress />}>
            <ReservationCalendar
              calendarRef={calendarRef}
              reservations={reservations}
              handleEventClick={handleEventClick}
              handleDateSelect={handleDateSelect}
              timezoneName={timezoneName}
              i18n={i18n}
              theme={theme}
            />
          </Suspense>
        </Box>

        {/* Varausdialogi */}
        <Suspense fallback={<div>Loading...</div>}>
          <ReservationDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            dialogMode={dialogMode}
            newReservation={newReservation}
            setNewReservation={setNewReservation}
            handleCreateReservation={handleCreateReservation}
            handleUpdateReservation={handleUpdateReservation}
            handleDeleteReservation={handleDeleteReservation}
            resetReservationForm={resetReservationForm}
            timezoneName={timezoneName}
            t={t}
          />
        </Suspense>

        {/* Snackbar-ilmoitus */}
        <Suspense fallback={<div>Loading...</div>}>
          <SnackbarNotification
            snackbar={snackbar}
            handleSnackbarClose={handleSnackbarClose}
          />
        </Suspense>
      </Box>
    </LocalizationProvider>
  );
}

export default Reservation;
